const scrollHorizontal = {
    init() {
        this.create();
        this.drapHorizontal();
    },

    create() {
        const scrollWrap = document.querySelectorAll("[data-scroll-horizontal]");
        if (scrollWrap.length === 0) return;

        scrollWrap.forEach((wrap) => {
            const content = wrap.querySelector("[data-content]");
            const items = wrap.querySelectorAll("[data-item]");
            let width = 0;

            const contentScrollHorizontal = () => {
                items.forEach((item) => {
                    width += item.offsetWidth;
                });

                if (width <= wrap.clientWidth) {
                    wrap.classList.remove("on");
                    content.style.width = "auto";
                } else {
                    wrap.classList.add("on");
                    content.style.width = `${width + items.length}px`;
                }

                width = 0;
            };

            contentScrollHorizontal();

            window.addEventListener("resize", () => {
                requestAnimationFrame(() => {
                    contentScrollHorizontal();
                });
            });
        });
    },

    drapHorizontal() {
        const slider = document.querySelectorAll("[data-scroll-horizontal]");

        slider.forEach((s) => {
            let isDown = false;
            let startX;
            let scrollLeft;

            s.addEventListener("mousedown", (e) => {
                isDown = true;
                s.classList.add("active");
                startX = e.pageX - s.offsetLeft;
                scrollLeft = s.scrollLeft;
            });

            s.addEventListener("mouseleave", () => {
                isDown = false;
                s.classList.remove("active");
            });

            s.addEventListener("mouseup", () => {
                isDown = false;
                s.classList.remove("active");
                s.classList.remove("mousemove");
            });

            s.addEventListener("mousemove", (e) => {
                if (!isDown) return;
                e.preventDefault();
                s.classList.add("mousemove");
                const x = e.pageX - s.offsetLeft;
                const walk = (x - startX) * 2; //scroll-fast
                s.scrollLeft = scrollLeft - walk;
            });
        });

        document.addEventListener("click", (e) => {
            const target = e.target;
            const name = target.dataset.name;

            if (target.matches("[data-horizontal-next]")) {
                e.preventDefault();
                const content = document.querySelector(`[data-content][data-name="${name}"]`);
                const left = content.scrollLeft + 100;
                content.scrollLeft = left;
            }

            if (target.matches("[data-horizontal-prev]")) {
                e.preventDefault();
                const content = document.querySelector(`[data-content][data-name="${name}"]`);
                const left = content.scrollLeft - 100;
                content.scrollLeft = left;
            }
        });
    },
};


scrollHorizontal.init();