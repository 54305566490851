const more = {
    init() {
        this.create();
    },
    create() {
        const moreContent = $("[data-more-content]");
        if (moreContent.length === 0) return;
        
        checkExpand();
        $(window).resize(function(event) {
            checkExpand();
        });



        $('body').on('click', '[data-more-element]', function(event) {
            event.preventDefault();
            const thisMoreElement = $(this);
            const name = thisMoreElement.data('name');
            const titleBefore = thisMoreElement.data('title-before');
            const titleAfter = thisMoreElement.data('title-after');
            const moreContentWithSameName = $(`[data-more-content][data-name="${name}"]`);
            const mb_num = moreContentWithSameName.data('mb-number');
            const dk_num = moreContentWithSameName.data('dk-number');
            let num = $(window).width() > 767 ? dk_num : mb_num;
            if (thisMoreElement.hasClass('on')) {
                thisMoreElement.removeClass('on').find('.txt').text(titleBefore);
                moreContentWithSameName.find('li').each(function() {
                    $(this).css('display', $(this).index() < num ? 'block' : 'none');
                });
            } else {
                thisMoreElement.addClass('on').find('.txt').text(titleAfter);
                moreContentWithSameName.find('li').css('display', 'block');
            }
        });

        function checkExpand() {
            moreContent.each(function() {
                const thisMoreContent = $(this);
                const mb_num = thisMoreContent.data('mb-number');
                const dk_num = thisMoreContent.data('dk-number');
                let num = $(window).width() > 767 ? dk_num : mb_num;
                const name = thisMoreContent.data('name');
                if(thisMoreContent.find('li').length > num) {
                    thisMoreContent.addClass('is-more');
                } else {
                    thisMoreContent.removeClass('is-more');
                }

                if(!thisMoreContent.find('[data-more-element]').hasClass('on')) {
                    thisMoreContent.find('li').each(function() {
                        $(this).css('display', $(this).index() < num ? 'block' : 'none');
                    });
                }
            });
        }
    },
};

$(document).ready(() => {
    more.init();
});