const form = {
  init() {
    this.create();
  },
  create() {
    $('[data-trigger-submit-form]').on('click', function() {
      const self = $(this);
      const form = self.closest('form');

      form.trigger('submit');
    });
  },
};

$(document).ready(() => {
  form.init();
});