import Swiper, { Navigation, Pagination, Thumbs, Autoplay } from "swiper";
import matchHeight from "jquery-match-height";

(function(window, document) {
    const slider = {
        init() {
            slider.create();
        },
        create() {
            if ($("[data-swiper-slider]").length) {
                $("[data-swiper-slider]").each(function() {
                    const self = $(this);
                    const id = self.attr("id");
                    const _name = self.data("name");
                    let _pagination = self.data("pagination");
                    const options = self.data("options");
                    if (_pagination != undefined) {
                        _pagination = '.swiper-pagination[data-name="' + _name + '"]';
                    } else {
                        _pagination = null;
                    }

                    const swiperSlides = $('.swiper-slide');
                    const slider = new Swiper(`#${id}`, {
                        modules: [Navigation, Pagination, Autoplay, Thumbs],
                        navigation: {
                            nextEl: '[data-slider-next][data-name="' + _name + '"]',
                            prevEl: '[data-slider-prev][data-name="' + _name + '"]',
                        },
                        pagination: {
                            el: _pagination,
                            clickable: true,
                            renderBullet: function(index, className) {
                                return (
                                    '<span class="' + className + '">' + (index + 1) + "</span>"
                                );
                            },
                        },
                        on: {
                            init: function () {
                                if($('.val').length > 0) {
                                    checkEqualHeight ();

                                    setTimeout(function () {
                                        checkEqualHeight ()
                                    }, 300);
                                }
                            }
                        },
                        ...options,
                    });

                    
                    function checkEqualHeight () {
                        swiperSlides.each(function(index, el) {
                            const valEles = $(this).find('.val');
                            if(valEles.length > 0 ) {
                                valEles.each(function(index, el) {
                                    const valEle = $('.val[data-index="'+ index +'"]');
                                    valEle.matchHeight({
                                        byRow: true,
                                        property: 'height',
                                        target: null,
                                        remove: false
                                    });
                                });
                            }
                        });
                    }

                    $('[data-hover-autoplay]').on('mouseenter', function(e){
                        var _n = $(this).data('name');
                        if(_n == _name) {
                            slider.autoplay.stop();
                        }
                    })

                    $('[data-hover-autoplay]').on('mouseleave', function(e){
                        var _n = $(this).data('name');
                        if(_n == _name) {
                            slider.autoplay.start();
                        }
                    })

                    $('body').on('click', '[data-nav-slider]', function(event) {
                        event.preventDefault();
                        $(this).addClass("on").parent().siblings().find('[data-nav-slider]').removeClass("on");
                        let _n = $(this).data('name');
                        if(_n == _name) {
                            let _index = $(this).parent().index();
                            slider.slideTo(_index, 1000);
                        }
                    });
                });
            }
        },
    };

    window.jQuery(document).ready(function() {
        slider.init();
    });
})(window, document);