global.fn = global.jQuery = global.$ = require("jquery");
import WOW from "wow.js";

new WOW().init();

import "./components/header";
import "./components/slider";
import "./components/scrollHorizontal";
import "./components/tooltip";
import "./components/more";
import "./components/chat-box";
import "./components/dropdown";
import "./components/comparison";
import "./components/form";
