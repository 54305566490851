const dropdown = {
    init() {
        dropdown.create();
    },
    create() {
        const dropdownEle = $("[data-dropdown-element]");
        if (!dropdownEle.length) return;

        dropdownEle.on('click', function(event) {
            event.preventDefault();
            $(this).parent().toggleClass('on');
            $(this).next('[data-dropdown-content]').slideToggle();
        });
    }
};

$(function() {
    dropdown.init();
});