global.jQuery = global.$ = require("jquery");

const HEADER_SELECTOR = 'header';
const MAIN_SELECTOR = 'main';
const BOX_SPACE_SELECTOR = 'header [data-box-space]';
const ANNOUNCEMENT_CONTENT_SELECTOR = '[data-announcement-content]';
const ANNOUNCEMENT_CLOSE_SELECTOR = '[data-announcement-close]';
const BOX_SPACE_PADDING_TOP = 80;

let flagSubNav = false,
    flagNav = false;
const nav = $('[data-nav');
const hambuger = document.querySelector('[data-hambuger]');

const header = {
    init() {
        this.announcement();
        this.sticky();
        this.controlNav();
    },
    openNav() {
        document.documentElement.classList.add('on-nav-mb');
        nav.addClass('on');
        hambuger.classList.add('on');

        setTimeout(function() {
            hambuger.setAttribute('aria-expanded', 'true');
        }, 299);
    },
    closeNav() {
        var _time = 0;
        hambuger.classList.remove('on');

        if (flagSubNav) {
            flagSubNav = false;

            const onLi = $('.ul-main > li.on');
            const subName = onLi.children('[data-expand]').data('name');
            onLi.removeClass('on');
            onLi.children('[data-expand]').attr('aria-expanded', 'false');
            onLi.children('[data-nav-dropdown-content][data-name="' + subName + '"]').removeClass('on');

            _time = 300;
        }

        setTimeout(function() {
            nav.removeClass('on')

            setTimeout(function() {
                document.documentElement.classList.remove('on-nav-mb');
                hambuger.setAttribute('aria-expanded', 'false');
            }, 300);

        }, _time);
    },
    openSubNav(subName) {
        const node = $(`[data-expand][data-name="${subName}"]`);
        const subNav = $('[data-nav-dropdown-content][data-name="' + subName + '"]');

        node.attr('aria-expanded', function (i, attr) {
            return attr == 'true' ? 'false' : 'true'
        });
        $('[data-nav-dropdown-content]').not(subNav).toggleClass('on');
        subNav.toggleClass('on').slideToggle('400');

        flagSubNav = true;
    },
    controlNav() {

        const ulMain = $('.ul-main');
        const lis = $('.ul-main > li');
        const navContent = $('[data-nav-dropdown-content]');
        navContent.addClass('w-transition');

        let timeout, timeout_ul_leave, timeout_nav_leave;
        let isMouseOverNav = false;

        lis.each(function(index, el) {

            const a = $(el).children('.nav-item');
            const name = a.data('name');

            $(el).on('mouseenter', () => {
                if (window.innerWidth >= 1024) {
                    clearTimeout(timeout_ul_leave);
                    clearTimeout(timeout_nav_leave);

                    timeout = setTimeout(() => {
                        $(el).addClass('on').siblings().removeClass('on');
                    }, 100);
                }
            });

            $(el).on('mouseleave', (event) => {
                if (window.innerWidth >= 1024) {
                    clearTimeout(timeout);

                    timeout_ul_leave = setTimeout(() => {
                        if (!isMouseOverNav) {
                            lis.removeClass('on');
                        }
                    }, 100);
                }
            });
        });

        ulMain.on('mouseleave', () => {
            if (window.innerWidth >= 1024) {
                isMouseOverNav = false;
            }
        });

        ulMain.on('mouseenter', () => {
            if (window.innerWidth >= 1024) {
                isMouseOverNav = true;
            }
        });

        navContent.on('mouseleave', (event) => {
            if (window.innerWidth >= 1024) {
                isMouseOverNav = false;
                timeout_nav_leave = setTimeout(() => {
                    if (!isMouseOverNav) {
                        lis.removeClass('on');
                    }
                }, 100);
            }
        });

        navContent.on('mouseenter', () => {
            if (window.innerWidth >= 1024) {
                isMouseOverNav = true;
            }
        });


        // nav mobile

        nav.find('[data-expand]').on('click', function(event) {
            var _this = $(this),
                _subName = _this.data("name");

            if (window.innerWidth < 1024) {
                event.preventDefault();

                _this.parent().toggleClass('is-current');

                header.openSubNav(_subName);
            }
        });

        nav.find('.nav-item').not('[data-expand]').on('click', function(event) {

            if (window.innerWidth < 1024 && $(this).attr('href').indexOf('#') != -1 ) {

                header.closeNav();
            }
        });

        hambuger.addEventListener('click', function() {
            if (this.getAttribute('aria-expanded') === 'true') {
                header.closeNav();
            } else {
                header.openNav();
            }
        });
    },
    announcement() {
        const announcementContent = $(ANNOUNCEMENT_CONTENT_SELECTOR);
        if (!announcementContent.length) return;

        const setPaddingTop = () => {
            if (announcementContent.is(':visible')) {
                $(HEADER_SELECTOR).addClass('is-announcement');
                const paddingTop = announcementContent.outerHeight(true);
                $(MAIN_SELECTOR).css('padding-top', paddingTop);
                $(BOX_SPACE_SELECTOR).css('padding-top', paddingTop + BOX_SPACE_PADDING_TOP);
            }
        };

        setPaddingTop();
        $(window).resize(setPaddingTop);

        $('body').on('click', ANNOUNCEMENT_CLOSE_SELECTOR, event => {
            event.preventDefault();
            announcementContent.slideUp(300);
            $(MAIN_SELECTOR).css('padding-top', 0);
            $(BOX_SPACE_SELECTOR).css('padding-top', BOX_SPACE_PADDING_TOP);
            $(HEADER_SELECTOR).removeClass('is-announcement');
        });
    },
    sticky() {
        let lastScrollTop = 0;
        let st = 0;
        let triggerStart = 0;
        let triggerTransition = 400;

        function sticky() {
            st = window.scrollY;
            triggerStart = $('header').outerHeight(true) * 1.5;
            triggerTransition = $('header').outerHeight(true) * 2.5;

            if (st > 0) {
                $('header').addClass('on');
            } else {
                $('header').removeClass('on');
            }

            if (st > triggerStart) {
                if (st > lastScrollTop) {
                    document.querySelector('body').classList.remove('scroll-up');
                    document.querySelector('body').classList.add('scroll-down');
                } else {
                    document.querySelector('body').classList.add('scroll-up');
                    document.querySelector('body').classList.remove('scroll-down');
                }

            } else {
                document.querySelector('body').classList.remove('scroll-up');
                document.querySelector('body').classList.remove('scroll-down');
            }
            lastScrollTop = st;
        }

        sticky();
        window.addEventListener('scroll', function() {
            sticky();
        });
        window.addEventListener('resize', function() {
            sticky();
        });
    }
};

header.init();