const chatbox = {
    init() {
        chatbox.create();
    },
    create() {
        const chatboxContent = document.querySelector("[data-chat-box-content]");
        chatboxContent.classList.add('w-transition');
        const chatboxElements = document.querySelectorAll("[data-chat-box-element]");
        if (!chatboxContent || !chatboxElements) return;

        document.body.addEventListener("click", event => {
            
            const chatboxElement = event.target.closest("[data-chat-box-element]");
            const chatboxClose = event.target.closest("[data-chat-box-close]");

            if (chatboxElement) {
                event.preventDefault();
                chatboxElement.classList.toggle("on");
                chatboxContent.classList.toggle("on");
            } else if (chatboxClose) {
                event.preventDefault();
                chatboxElements.forEach(element => element.classList.remove("on"));
                chatboxContent.classList.remove("on");
            } else if (!chatboxContent.contains(event.target)) {
                chatboxElements.forEach(element => element.classList.remove("on"));
                chatboxContent.classList.remove("on");
            }
        });
    }
};

document.addEventListener("DOMContentLoaded", () => {
    chatbox.init();
});