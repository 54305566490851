const comparison = {
    init() {
        this.create();
    },
    create() {
        const comparisonContent = $("[data-comparison-content]");
        const swiperSlides = $('.swiper-slide');
        let _flagEqual = false;
        if (comparisonContent.length === 0) return;

        $('body').on('click', '[data-comparison-element]', function(event) {
            event.preventDefault();
            let isDesktop = $(window).width() > 767 ? true : false;
            const thiscomparisonElement = $(this);
            const name = thiscomparisonElement.data('name');
            const comparisonContentWithSameName = $(`[data-comparison-content][data-name="${name}"]`);

            if (thiscomparisonElement.hasClass('on')) {
                thiscomparisonElement.removeClass('on').find('.txt').text('Expand full feature comparison');
                comparisonContentWithSameName.removeClass('on');
                $('body, html').removeClass('no-scroll-mb');
                
                if(isDesktop) { 
                    comparisonContentWithSameName.slideUp(800);
                }
                
            } else {
                thiscomparisonElement.addClass('on').find('.txt').text('Hide full feature comparison');
                comparisonContentWithSameName.addClass('on');
                $('body, html').addClass('no-scroll-mb');
                if(isDesktop) {
                    comparisonContentWithSameName.slideDown('800', function() {
                        if(!_flagEqual) {
                            _flagEqual = true;
                            swiperSlides.each(function(index, el) {
                                const valEles = $(this).find('.val');
                                if(valEles.length > 0 ) {
                                    valEles.each(function(index, el) {
                                        const valEle = $('.val[data-index="'+ index +'"]');
                                        valEle.matchHeight({
                                            byRow: true,
                                            property: 'height',
                                            target: null,
                                            remove: false
                                        });
                                    });
                                }
                            });
                        }
                    });
                }
            }
        });

        $('body').on('click', '[data-comparison-close]', function(event) {
            event.preventDefault();
            const name = $(this).data('name');
            console.log(name);

            const thiscomparisonElement = $(`[data-comparison-element][data-name="${name}"]`);
            const comparisonContentWithSameName = $(`[data-comparison-content][data-name="${name}"]`);
            
            let isDesktop = $(window).width() > 767 ? true : false;
            $('body, html').removeClass('no-scroll-mb');
            thiscomparisonElement.removeClass('on').find('.txt').text('Expand full feature comparison');
            comparisonContentWithSameName.removeClass('on');

            if(isDesktop) { 
                comparisonContentWithSameName.slideUp(800);
            }
        })
    },
};

$(document).ready(() => {
    comparison.init();
});